<script setup lang="ts">
import type { IEvent } from "@/types";

const props = defineProps<{
  event: IEvent;
}>();

const { t } = useI18n();
</script>

<template>
  <UListItem
    v-if="event?.permissions?.canAddLineup"
    icon="lineup"
    :text="t('labels.lineup')"
    :to="{ path: `/events/lineup/${props.event.id}` }"
  />
</template>
